import * as timeHelper from "../helpers/timeHelper";

export const landCooldownMixin = {
    data() {
        return {
            interval: null,
            dateNow: new Date(),
        }
    },
    mounted() {
        this.interval = setInterval(() => {
            this.dateNow = new Date();
        }, 2500);
    },
    destroyed() {
        clearInterval(this.interval);
    },
    computed: {
        isCooldownActive() {
            return timeHelper.dateToUtcMicroTimestamp(this.dateNow) <= this.cooldownDateMicroTimestampUTC;
        },
        cooldownDateMicroTimestampUTC() {
            if (this.item?.wizarre?.cooldown_timestamp) {
                return timeHelper.timestampToMicroTimestamp(this.item.wizarre.cooldown_timestamp);
            }
            return 0;
        },
    },
}
