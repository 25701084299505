export function dateToUtcMicroTimestamp(dateObj) {
    return dateObj.getTime();
}

export function dateToTimestamp(dateObj) {
    return Math.round(dateToUtcMicroTimestamp(dateObj) / 1000);
}

export function isUnixTimestampPassed(microTimestamp) {
    return dateToUtcMicroTimestamp(new Date()) >= timestampToMicroTimestamp(microTimestamp);
}

export function dbDateToMicroTimestamp(dbDateString) {
    return new Date(dbDateString).getTime()
}

export function isDbDatePassed(dbDateString) {
    return dateToUtcMicroTimestamp(new Date()) >= dbDateToMicroTimestamp(dbDateString);
}

export function timestampToMicroTimestamp(timestamp) {
    return timestamp * 1000;
}

export function UTCToLocalTimeMicroTimestamp(UTCtimestamp) {
    const now = new Date();
    return UTCtimestamp + now.getTimezoneOffset() * 60 * 1000
}

export function nowInSeconds() {
    return (new Date()).getTime() / 1000;
}

export function timestampToTimeString(timestamp) {
    return new Date(timestamp).toLocaleString('pl-PL', {});
}

export function timestampToHoursMinutesSeconds(timestampPeriod) {
    let seconds = Math.floor((timestampPeriod) % 60);
    let minutes = Math.floor((timestampPeriod / 60) % 60);
    let hours = Math.floor((timestampPeriod / 60 / 60));
    return formatTime(hours) + ':' + formatTime(minutes) + ':' + formatTime(seconds);
}

function formatTime(value) {
    if (value < 10) {
        return "0" + value;
    }
    return value;
}
