var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.element
    ? _c(
        "div",
        { style: _vm.styleWidthHeight },
        [
          _c("img", {
            class: _vm.inline ? "" : "img-absolute",
            style: _vm.styleWidthHeight,
            attrs: { src: _vm.iconImage },
          }),
          _vm._t("default"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }