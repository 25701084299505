var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: "land-id-" + _vm.item.id,
      attrs: {
        "data-id": _vm.item.id,
        "data-active-cooldown": _vm.isCooldownActive,
      },
    },
    [
      !_vm.item.name
        ? _c("v-skeleton-loader", {
            staticClass: "mt-2",
            attrs: { type: "card", width: "150", height: "188" },
          })
        : _vm._e(),
      _vm.item.name
        ? _c(
            "div",
            {
              staticClass: "mini-item mini-land",
              class: [
                "mini-land-rarity-" + _vm.item.wizarre.rarity,
                _vm.isCooldownActive ? "active-cooldown" : "",
              ],
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    right: "",
                    "max-width": "400",
                    "content-class": "mini-tooltip",
                    disabled: _vm.popupDisabled || !_vm.item.description,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  { staticClass: "item-box" },
                                  "div",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("div", { staticClass: "item-header" }, [
                                  !_vm.item.wizarre
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.item.name || "#" + _vm.item.id
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.item.wizarre
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            "text-rarity-" +
                                            _vm.item.wizarre.rarity,
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.item.name ||
                                                  "#" + _vm.item.id
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm.item.thumbnail
                                  ? _c("div", { staticClass: "item-content" }, [
                                      _c("img", {
                                        staticClass: "thumbnail-img",
                                        attrs: { src: _vm.item.thumbnail },
                                      }),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "v-overlay",
                                  {
                                    attrs: {
                                      absolute: true,
                                      value: _vm.isCooldownActive,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "cooldown-timer" },
                                      [
                                        _c("br"),
                                        _vm._v(" Recharging"),
                                        _c("br"),
                                        _c("timer", {
                                          attrs: {
                                            deadlineTimestamp:
                                              _vm.cooldownDateMicroTimestampUTC /
                                              1000,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3618630292
                  ),
                },
                [
                  _vm.item.wizarre
                    ? _c("span", [
                        _c(
                          "span",
                          { staticClass: "tooltip-title" },
                          [
                            _c("icon-element", {
                              attrs: {
                                element: _vm.element,
                                "img-size": "20px",
                              },
                            }),
                            _vm._v(" " + _vm._s(_vm.item.name) + " "),
                          ],
                          1
                        ),
                        _vm._v(" Rarity: "),
                        _c(
                          "b",
                          [
                            _c("rarity-text", {
                              attrs: { rarity: _vm.item.wizarre.rarity },
                            }),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._v(" Type: "),
                        _c("b", [_vm._v(_vm._s(_vm.item.wizarre.typeName))]),
                        _c("br"),
                        _vm._v(" Description: "),
                        _c("b", [_vm._v(_vm._s(_vm.item.description))]),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }