import AssetDTO from "@/classes/asset/AssetDTO";

export default class OfferDTO {
    /**
     *
     * @type {number}
     */
    id
    /**
     *
     * @type {AssetType}
     */
    type
    /**
     *
     * @type {AssetDTO}
     */
    assetDTO
    /**
     *
     * @type {number}
     */
    price
    /**
     *
     * @type {Number}
     */
    amount
    /**
     *
     * @type {string}
     */
    creator
    /**
     *
     * @type {string}
     */
    taker
    /**
     *
     * @type {OfferStatus | string}
     */
    status
    /**
     *
     * @type {number | string}
     */
    created_on
    /**
     *
     * @type {number | string}
     */
    updated_on

    /**
     *
     * @param {Object} offer
     */
    constructor(offer) {
        offer && Object.assign(this, offer);
        this.assetDTO = new AssetDTO(this.assetDTO);
    }
}
