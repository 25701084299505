<template>
    <div v-if="element" :style="styleWidthHeight">
        <img :src="iconImage" :style="styleWidthHeight" :class="inline?'':'img-absolute'"/>
        <slot></slot>
    </div>
</template>

<script>

export default {
    props: {
        element: String,
        imgSize: {
            type: String,
            default: 'auto',
        },
        size: {
            type: Number,
            default: 32,
        },
        inline: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {
        styleWidthHeight() {
            return 'width:' + this.imgSize + ';height:' + this.imgSize;
        },
        iconImage() {
            let elem = this.element.toLowerCase();
            return '/img/icons/element/' + this.size + '/' + elem + '.png';
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
div {
    display: inline-block;
    position: relative;

    img.img-absolute {
        top: 5px;
        position: absolute;
    }
}
</style>
