<template>
    <div
        :class="'land-id-'+item.id"
        :data-id="item.id"
        :data-active-cooldown="isCooldownActive"
    >
        <v-skeleton-loader
            v-if="!item.name"
            type="card"
            width="150"
            height="188"
            class="mt-2"
        >
        </v-skeleton-loader>

        <div class="mini-item mini-land" v-if="item.name"
             :class="['mini-land-rarity-'+item.wizarre.rarity, isCooldownActive?'active-cooldown':'']"
        >
            <v-tooltip right max-width="400" content-class="mini-tooltip" :disabled="popupDisabled || !item.description">

                <template v-slot:activator="{ on, attrs }">
                    <div class="item-box"
                         v-bind="attrs"
                         v-on="on"
                    >
                        <div class="item-header">
                            <span v-if="!item.wizarre">
                                {{ item.name || '#' + item.id }}
                            </span>
                            <span v-if="item.wizarre" :class="'text-rarity-'+item.wizarre.rarity">
                                {{ item.name || '#' + item.id }}
                            </span>
                        </div>


                        <div class="item-content" v-if="item.thumbnail">
                            <img :src="item.thumbnail" class="thumbnail-img"/>
                        </div>

                        <v-overlay
                            :absolute="true"
                            :value="isCooldownActive"
                        >
                            <span class="cooldown-timer">
                                <br/>
                                Recharging<br/>
                                <timer :deadlineTimestamp="(cooldownDateMicroTimestampUTC/1000)"></timer>
                            </span>
                        </v-overlay>

                    </div>

                </template>

                <span v-if="item.wizarre">
                <span class="tooltip-title">
                    <icon-element :element="element" img-size="20px"></icon-element>
                    {{ item.name }}
                </span>
                Rarity: <b><rarity-text :rarity="item.wizarre.rarity"></rarity-text></b><br/>
                Type: <b>{{ item.wizarre.typeName }}</b><br/>
                Description: <b>{{ item.description }}</b>
            </span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>

import RarityText from "./rarity-text";
import IconElement from "../../icon-element";
import Timer from "../../timer";
import {landCooldownMixin} from "../../../mixins/landCooldownMixin";
import OfferDTO from "@/classes/market/OfferDTO";

export default {
    components: {Timer, IconElement, RarityText},
    mixins: [landCooldownMixin],
    props: {
        item: Object,
        disableClicksOnCd: Boolean,
        popupDisabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {}
    },
    mounted() {
    },
    computed: {
        element() {
            if ('undefined' !== typeof this.item.wizarre) {
                return this.item.wizarre.element || '';
            }
            return '';
        }
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.mini-item {
    .item-box {
        img.thumbnail-img {
            margin: 70px 0 0 6px;
            height: 110px;
        }
    }

    .cooldown-timer {
        font-size: 120%;
    }
}

.mini-item {
    &.mini-land-rarity-rare {
        .item-box {
            @include bg-card-rare;

            &:hover {
                @include bg-card-rare-hover;
            }
        }
    }

    &.mini-land-rarity-epic {
        .item-box {
            @include bg-card_epic;

            &:hover {
                @include bg-card_epic-hover;
            }
        }
    }

    .item-box {
        @include bg-card_normal;

        &:hover {
            @include bg-card_normal-hover;
        }
    }
}

.mini-item.active-cooldown {
    &.mini-land-rarity-rare {
        .item-box {
            &:hover {
                @include bg-card-rare;
            }
        }
    }

    &.mini-land-rarity-epic {
        .item-box {
            &:hover {
                @include bg-card_epic;
            }
        }
    }

    .item-box {
        &:hover {
            @include bg-card_normal;
        }
    }
}

.element-icon {
    top: 46px;
    left: -8px;
    position: absolute;
}

.rarity-icon {
    width: 50px;
    bottom: 2px;
    right: 0;
    position: absolute;
}

.item-header > .text-rarity-normal {
    color: #000;
}
</style>
