

export default class AssetDTO {
    /**
     *
     * @type {Number}
     */
    id = undefined
    /**
     *
     * @type {String}
     */
    name = undefined
    /**
     *
     * @type {String}
     */
    description = undefined
    /**
     *
     * @type {String}
     */
    effect = undefined
    /**
     *
     * @type {String}
     */
    external_url = undefined
    /**
     *
     * @type {String}
     */
    image = undefined
    /**
     *
     * @type {String}
     */
    type = undefined
    /**
     *
     * @type {String}
     */
    created_on = undefined
    /**
     *
     * @type {String}
     */
    owner = undefined
    /**
     *
     * @type {AssetAttributes[]}
     */
    attributes = []
    wizarre = {}
    /**
     *
     * @type {String}
     */
    thumbnail_100 = undefined
    /**
     *
     * @type {String}
     */
    thumbnail_150 = undefined
    /**
     *
     * @type {String}
     */
    thumbnail_300 = undefined


    /**
     *
     * @param {Object} asset
     */
    constructor(asset) {
        asset && Object.assign(this, asset)
    }
}

export class AssetAttributes {
    trait_type = String
    value = String
}
